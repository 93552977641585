<template>
    <NuxtLink :to="localePath('/')">
        <span class="text-3xl">oase<strong>BW</strong></span>
    </NuxtLink>
</template>

<script setup>
let isDark = null;

if (useFeature('darkMode')) {
    isDark = useDark({
        disableTransition: false
    })
}
</script>